<script setup>
import { getDefaultOptions } from '@/pages/kvartalsrapport/charts/chartUtils.js';
import { useKvartalsrapportStore } from '@/stores/kvartalsrapportStore.js';
import dayjs from 'dayjs';
import { formatNumber } from '@/pages/kvartalsrapport/kvartalsrapportUtil';

const props = defineProps({
  print: {
    type: Boolean,
    default: false,
  },
});

const store = useKvartalsrapportStore();

let detteAar = [];
let forrigeAar = [];

function prepareData() {
  detteAar = [];
  forrigeAar = [];
  const salgProduktGrupper = store.udvikling.salgProduktGrupper;
  const salg = salgProduktGrupper.filter((gruppe) => gruppe.salgProduktGruppeNavn === 'Pension')[0];
  let akkumuleret = 0;
  let prevUgeNr = 0;
  salg.ugeSalg.forEach((x) => {
    if (dayjs(x.periodeFra).year() === dayjs(store.rapport.startDato).year() - 1) {
      let ugeNr = x.ugeNr;
      if (ugeNr == 1 && prevUgeNr == 52) {
        ugeNr = 53;
      }
      akkumuleret += x.beloeb / 1000000;
      forrigeAar.push({ x: ugeNr, y: akkumuleret });
      prevUgeNr = ugeNr;
    }
  });

  akkumuleret = 0;
  prevUgeNr = 0;
  salg.ugeSalg.forEach((x) => {
    if (dayjs(x.periodeFra).year() === dayjs(store.rapport.startDato).year()) {
      let ugeNr = x.ugeNr;
      if (ugeNr == 1 && prevUgeNr == 52) {
        ugeNr = 53;
      }
      akkumuleret += x.beloeb / 1000000;
      detteAar.push({ x: ugeNr, y: akkumuleret });
      prevUgeNr = ugeNr;
    }
  });
}

function getOptions() {
  return Object.assign({}, getDefaultOptions(props.print), {
    stroke: {
      width: 3,
    },
    chart: {
      ...getDefaultOptions(props.print).chart,
      dropShadow: {
        enabled: false,
      },
    },
    dataLabels: {
      formatter: function (value) {
        return formatNumber(value, 1, 1);
      },
    },
    xaxis: {
      tickAmount: 10,
    },
    colors: ['#e49d3b', '#6bb8bc'],
  });
}

function getSeries() {
  prepareData();

  const series = [
    {
      name: 'Forrige år',
      data: forrigeAar,
    },
    {
      name: 'Dette år',
      data: detteAar,
    },
  ];

  return series;
}
</script>

<template>
  <div class="chart" v-if="store.udvikling.salgProduktGrupper">
    <apexchart height="100%" type="line" :options="getOptions()" :series="getSeries()"></apexchart>
  </div>
</template>

<style scoped lang="scss">
.chart {
  height: 200px;
}
</style>
